<template>
  <div class="page-dds">
    <van-nav-bar title="待定损多单收款--详情" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <van-row class="tl">
          <van-col span="13">待定方：{{baseObj.ddsName}}</van-col>
          <van-col span="11">收款日期：{{baseObj.bizDate?baseObj.bizDate.substring(0,10):""}}</van-col>
        </van-row>
        <van-row class="tl">
          <van-col span="5">收款金额：</van-col>
          <van-col span="8" class="cRed bold font_14">{{ baseObj.total }}</van-col>
          <van-col span="4">录单人：</van-col>
          <van-col span="7">{{ baseObj.recordName }}</van-col>
          <van-col span="24" class="tl">支付方式：{{baseObj.payTypeStr}}</van-col>
        </van-row>
        <div class="fkpz">
          <div style="padding-top: 10px;">
            <div>收款截图</div>
          </div>
          <div class="imgdiv" v-for="(item, is) in picDOS" :key="is">
            <img class="pzimgs" @click="toimgdan(item)" :src="item" alt="">
          </div>
        </div>
      </van-row>
      <van-row class="mian_row" style="font-weight: bold;border-top:1px solid #f5f5f5;">
        <van-col span="2">#</van-col>
        <van-col span="5">车牌号</van-col>
        <van-col span="5">保险公司</van-col>
        <van-col span="4">定损金额</van-col>
        <van-col span="4">服务费</van-col>
        <van-col span="4">到账金额</van-col>
      </van-row>
    </div>
    <div class="pt" v-if="list.length > 0">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx">
          <van-row class="mian_row">
            <van-col span="2">{{ idx }}</van-col>
            <van-col span="5" class="font_13 slh">{{ item.carNo }}</van-col>
            <van-col span="5" class="slh">{{ item.insureCompany }}</van-col>
            <van-col span="4">{{ item.dsAmount }}</van-col>
            <van-col span="4">{{ item.serviceAmount }}</van-col>
            <van-col span="4" class="tr">{{item.accountAmount}}</van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-empty v-else description="暂无数据~" />
    <div class="botbox flex">
      <van-button v-if="baseObj.status=='已审核'" type="primary" disabled>{{baseObj.status}}</van-button>
      <van-button type="warning" v-else>{{baseObj.status}}</van-button>
    </div>
  </div>
</template>

<script>
import { ddsDetail } from "@/api/check";
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // if(from.path == '/pending' || from.path == '/examinedetails'){
        vm.id=vm.$route.query.id
        vm.initData()
      // }
    });
  },
  data() {
    return {
      list: [],
      gid: this.$route.query.gid,
      id: this.$route.query.id,
      key: "",
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(),
      timeshow:false,
      baseObj:{
        ddsName:"", //待定方
        bizDate:"", //收款日期
        total:"", //总金额
        recordName:"", //录单人
        status:""
      },
      picDOS:[],
      
      inOptions:[],
      carNo:"",
      accidentOptions:[]
    };
  },
  methods: {
    onLoads() {
      this.initData()
    },
    getlist() {
      return;
    },

    back() {
      this.$router.go(-1);
    },
    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    initData(){
      let params = {
        ddsAccountId:this.id
      }
      ddsDetail(params).then(res=>{
        if(res.code == 200){
          let obj = res.data.ddsAccountDO
          if(obj){
            this.baseObj = obj
          }
          this.list = res.data.detailsDOS || []
          this.picDOS = res.data.picDOS || []
        }
      })
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    // this.getlist()
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.page-dds{
  background: #f6f6f6;
  color: #000;
  font-size: 14px;
  .el-select{
    max-width: 100px;
  }
}
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

::v-deep .topss {
  padding: 10px;
  background: #fff;
  .el-input__inner{
   height: 24px;
   font-size: 13px;
  }
  .el-input__suffix{
    margin-top: 8px;
  }
  .date{
    border: 1px solid #eee;
    padding: 4px 6px;
    border-radius: 4px;
  }
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  line-height: 35px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  // padding-top: 46px;
}

.flx {
  display: flex;
  align-items: center;
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}


.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.mian_row {
  line-height: 35px;
  font-size: 14px;
  padding: 0 10px;
  background: #fff;
  .van-col{
    text-align: center;
  }
}
.fkpz {
  display: flex;
  margin-top: 5px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
  //padding: 0 15px;
  font-size: 14px;
  height: 80px;
  line-height: 16px;
  .scdiv {
    width: 45px;
    height: 70px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .imgdiv {
    width: 45px;
    height: 70px;
    margin-left: 10px;
    position: relative;

    .pzimgs {
      width: 45px;
      height: 70px;
    }
    .scimgs {
      width: 24px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
    }
  }
}

.botbox {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 9;
  .van-button{
    flex: 1;
  }
}
::v-deep .van-button--warning{
  background-color: #ccc;
  border-color: #ccc;
}
</style>